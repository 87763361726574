import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import './UpdateAdminDetails.css';

const UpdateTutorDetails = ({ user }) => {
  const { REACT_APP_API_URL } = process.env;
  const { token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { register, handleSubmit, errors } = useForm();
  const [firstName, setFirstName] = useState('first name');
  const [lastName, setLastName] = useState('last name');
  const [email, setEmail] = useState('email');
  const [lastupdated, setLastupdated] = useState('01 01 2000');

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setLastupdated(user.updatedAt);
    }
  }, [user]);

  const updateFirstName = e => {
    setFirstName(e.target.value);
  };

  const updateLastName = e => {
    setLastName(e.target.value);
  };

  const updateEmail = e => {
    setEmail(e.target.value);
  };

  const onSubmit = async data => {
    try {
      await sendRequest(
        `${REACT_APP_API_URL}/api/admin/update-admin/${userId}`,
        'PATCH',
        JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }
      );
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <Spinner
            className="spinner-primary"
            classNameText="spinner-text-primary"
            message="updating"
          />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span className="inputError">
              {errors.firstName &&
                errors.firstName.type === 'required' &&
                'First name is required'}
              {errors.firstName &&
                errors.firstName.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>
          <Input
            id="firstName"
            name="firstName"
            groupFront="first name"
            value={firstName}
            onChange={updateFirstName}
            refs={register({ required: true, minLength: 2 })}
          />

          <label>
            <span className="inputError">
              {errors.lastName &&
                errors.lastName.type === 'required' &&
                'Last name is required'}
              {errors.lastName &&
                errors.lastName.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>
          <Input
            id="lastName"
            name="lastName"
            groupFront="last name"
            value={lastName}
            onChange={updateLastName}
            refs={register({ required: true, minLength: 2 })}
          />

          <label>
            <span className="inputError">
              {errors.email &&
                errors.email.type === 'required' &&
                'Email is required'}
            </span>
          </label>
          <Input
            id="email"
            name="email"
            groupFront="email"
            value={email}
            onChange={updateEmail}
            refs={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
              },
            })}
          />
          <p className="personal-settings-container-p">
            Last updated: {moment(lastupdated).format('MMM Do YYYY')}
          </p>
          <Button
            type="submit"
            className="button-primary personal-update-btn"
            title="update"
          />
        </form>
      )}
    </Fragment>
  );
};

export default UpdateTutorDetails;
