import React from 'react'
import { NavLink } from 'react-router-dom'; 



import './mainNav.css';

 const MainNav = () => {


    return (
        <div className="main-nav__wrapper">
            <ul className="nav-links">
                <li>
                    <NavLink to="/">Dashboard</NavLink>
                </li>
                <li>
                    <NavLink to="/companies">Companies</NavLink>
                </li>
                <li>
                    <NavLink to="/ships">Ships</NavLink>
                </li>
                <li>
                    <NavLink to="/ports">Ports</NavLink>
                </li>
                <li>
                    <NavLink to="/itineraries">Itineraries</NavLink>
                </li>
                <li>
                    <NavLink to="/users">Users</NavLink>
                </li>
                <li>
                    <NavLink to="/settings">Settings</NavLink>
                </li>
            </ul>
 
        </div>
    )
}


export default MainNav;