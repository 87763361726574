import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';

import './UpdatePortLanguage.css';

const UpdatePortLanguage = ({id, language}) => {

    const auth = useContext(AuthContext);
    const { isLoading, sendRequest } = useHttpClient();
    const { register, handleSubmit} = useForm();
    const [loadedLanguage, setLoadedLanguage] = useState('');

    useEffect(() => {
        language && setLoadedLanguage(language);
    }, [language])

    const updateLangHandler = data => {
        console.log(data.lang);
        setLoadedLanguage(data.lang);
    };

    const onSubmit = async (data) => {

        try {
            const response = await sendRequest(
              `https://api.docked.co/api/ports/update-port/language/${id}`,
              'PATCH',
              JSON.stringify({
                language: data.lang
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            
          } catch (err) {
            console.log(err);
          }
    }

    return (
        <form className='update-port-lang-form' onSubmit={handleSubmit(onSubmit)}>
        <Input
              id="lang"
              name="lang"
              type="text"
              groupFront="Language"
              refs={register()}
              value={loadedLanguage}
              onChange={handleSubmit(updateLangHandler)}
            />
           {!isLoading ?  <Button className='btn-lang-update' title='update' type='submit' /> : <p>Loading...</p>}
        </form>
    )
}

export default UpdatePortLanguage;