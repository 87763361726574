import React, { useState, useEffect } from 'react';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../../../shared/components/UIElements/LoadingSpinner';
import './PortSelect.css';
import Input from '../../../../shared/components/FormElements/Input';

const ChoosePort = ({ cancel, portSelected }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [ports, setPorts] = useState([]);
  const [filteredPorts, setFilteredPorts] = useState([]);


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.docked.co/api/ports`,
          'POST'
        );
        setPorts(responseData.ports);
        setFilteredPorts(responseData.ports);
        console.log(responseData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchQuestions();
  }, [sendRequest]);

  const updateSearchList = e => {
   
    const query = e.target.value.toLowerCase();
    
    const update = ports.filter(p =>
        p.portName.toLowerCase().includes(query)
      );

      setFilteredPorts(update);

      console.log(query);
      console.log(update);

  };

  const portSelectionHandler = p => {
    portSelected(p);
  };


  return (
    <section id="choose-port">
      <p>Select a port.</p>

      <ErrorModal error={error} onClear={clearError} />

      <div className="choose-port-content">
        <div className="choose-port-search">
          <Input
            id="port"
            name="port"
            placeholder="Search port name"
            className="no-group"
            onChange={updateSearchList}
          />
        </div>
        <div className="choose-port-list">

          {isLoading && (
            <div className="center">
              <LoadingSpinner />
            </div>
          )}

          {filteredPorts.length !== 0 &&
            !isLoading &&
            filteredPorts.map(p => (
              <div
                tabIndex="0"
                key={p.id}
                className="choose-port-item"
                onClick={() => portSelectionHandler(p)}
              >

                <p>{p.portName}</p>
                <p>{p.country}</p>
              </div>
            ))}
        </div>
        <div className="choose-port-actions">
         
        </div>
      </div>
    </section>
  );
};

export default ChoosePort;
