import React, {useState}  from 'react';

import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import ShipList from '../components/ShipList';
import ShipDetail from '../components/ShipDetail';
import './Ships.css';
import AddShip from '../components/AddShip';

const shipsMenu = [
  {
    id: 0,
    title: 'list'
  },
  {
    id: 1,
    title: 'add Ship'
  }
]

const Ships = props => {
  
  const [showList, setShowList] = useState(true);
  const [showAddShip, setShowAddShip] = useState(false);
  const [showShip, setShowShip] = useState(true);
  const [shipId, setShipId] = useState();

  const selectShipHandler = id => {
    setShowShip(true);
    setShipId(id);
    console.log(id);
    setShowList(false);
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowShip(false);
        setShowAddShip(false);
        setShowList(true);        
        break;
      case 1:
          setShowShip(false);
          setShowAddShip(true);
          setShowList(false);        
        break;
    
      default:
        break;
    }
  }
 

  
  return (
    <div className="page__wrapper">
    <Header title={'Ships'} />
    <SubMenu items={shipsMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
    {showShip && shipId && <ShipDetail shipId={shipId} closeDetail={()=>subMenuStateHandler(0)}/> }
    {showList && <ShipList selectShipHandler={selectShipHandler}/> }
    {showAddShip && <AddShip update={()=>subMenuStateHandler(0)} />}
    </div>
  </div>
  );
};

export default Ships;
