import React from 'react'

import './logo.css'

 const Logo = () => {
    return (
        <div className="logo__wrapper">
             <img className="logo-img" src="https://content.docked.co/img/logos/logo2.svg" alt="logo" />
        </div>
    )
}


export default Logo;