import React, { useEffect, useState, useContext } from 'react';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import TableRow from './ItineraryRow';
import TableHeaders from './tableHeader';
import Button from '../UIElements/Buttons/Button';
import Modal from '../UIElements/Modal/Modal';
import AddPortDayForm from '../Forms/AddPortDayForm';
import EditPortDayForm from '../Forms/EditPortDayForm';


import './ItineraryList.css';


const Itinerary = ({ params, reload, getPosition, scroll }) => {
  const tableHeaders = [
    'Date',
    'PortName',
    'Country',
    'Arrival',
    'Departure',
    'type',
    'last Updated',
    'Created',
  ];
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedPortDays, setLoadedPortDays] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [addPortDay, setAddPortDay] = useState(false);
  const [showEditPortDay, setShowEditPortDay] = useState(false);
  const [editPortDay, setEditPortDay] = useState();
  const [shipId, setShipId] = useState()
  const [updateList, setUpdateList] = useState();

  const selectedRowHandler = (data) => {
    setEditPortDay(data);
    setShowEditPortDay(true);
  }

  function add_years(dt,n) {
    return new Date(dt.setFullYear(dt.getFullYear() + n));      
 }

  useEffect(() => {

    if ( params?.startDate) {
        setStartDate(params.startDate);
    } else {
        const now = new Date();
        setStartDate(now);
    }

    if (params?.endDate) {
        setEndDate(params.endDate);
    } else {
        const now = new Date();
        setEndDate(add_years(now, 2));
        
    }
    if ( params?.shipId) {
      setShipId(params.shipId)
    }
  
    const fetchItinerary = async () => {


      try {
        const responseData = await sendRequest(
          `https://api.docked.co/api/port-day/ship-itinerary`,
          'POST',
        JSON.stringify({
          shipId: params.shipId,
          startDate: startDate,
          endDate: endDate
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token
        }
        );
        
        setLoadedPortDays(responseData.ports);
        scroll()
      
        
      } catch (err) {
        console.log(err);
      }
    };

    if (params) {
        console.log(params);
        fetchItinerary();
    }

  
}, [sendRequest, params, updateList, reload]);




const update =() => {
  cancelModal()
  setUpdateList(updateList ? false : true);
}

const cancelModal = () => {
  setAddPortDay(false);
  setShowEditPortDay(false);
}



  return (
    <React.Fragment>
  {shipId && 
    <Modal show={addPortDay} hideButton={true} >
      <AddPortDayForm shipId={shipId} cancel={cancelModal} update={update}/>
    </Modal>
  }

  <Modal show={showEditPortDay} hideButton={true} >
        <EditPortDayForm portDay={editPortDay} cancel={cancelModal} getPosition={getPosition} update={update}/>
    </Modal>


    <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading &&
      <div  className={`table__wrapper}`}>
      <Button title='Add PortDay' onClick={()=> setAddPortDay(true)}/>
        <table className={`table }`}>
          <thead className='noHover'>
            <tr className="theader">
              {tableHeaders.map((item, index) => (
                <TableHeaders key={index} headerTitle={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {loadedPortDays
              ? loadedPortDays
                  .slice(0)
                  .reverse()
                  .map(portDay => (
                    <TableRow
                      key={portDay.id}
                      portDay={portDay}
                      port={portDay.port}
                      selected={selectedRowHandler}
                    />
                  ))
              : null}
          </tbody>
        </table>
      </div>
      }
    </React.Fragment>
  );
};

export default Itinerary;
