import React from 'react';
import Card from '../../shared/components/UIElements/Card';
import CompanyLogo from '../../shared/components/UIElements/CompanyLogo';

import './companyItem.css'

const CompanyItem = ({company, selected}) => {

    const selectedCompany = () => {
        selected(company)
        console.log('pressed');
    }

    return (
        <Card className="company-item-card" onClick={selectedCompany}>
           <CompanyLogo className='badge-img' src={company.name} alt={company.name} />
            <h2>{company.name}</h2>
        </Card>
    )
}

export default CompanyItem;