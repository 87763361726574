import React, {useEffect, useState} from 'react';
import './CompanyLogo.css';


const CompanyLogo = props => {

    const name = props.src.replace(/\s/g , "-");
    const publicUrl = './operator-logo/' + name + '.png';
    console.log(publicUrl);

   
    const [src, setSrc] = useState()

    useEffect(() => {
        setSrc(publicUrl);
    }, [publicUrl]);

    return (
        <div className={`Company-icon-wrapper ${props.className}`}>
        {src && 
           <img 
           className='company-icon'
            src={src} 
            alt={props.alt} 
            />
            }
        </div>
    )
}

export default CompanyLogo;