import React from 'react';

import Header from '../../shared/components/header/Header';
import MyAccountSettings from '../components/MyAccountSettings';
import './Settings.css';

const Settings = props => {
  return (
    <div className="page__wrapper">
    <Header title={'Settings'} />
    <div className="page-content">
      <MyAccountSettings />


    </div>
  </div>
  );
};

export default Settings;
