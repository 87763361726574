import React, { useEffect, useState, useContext } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import moment from 'moment';
import 'moment/locale/en-gb';

const LastPortDay = ({ shipId }) => {

    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest } = useHttpClient();
    const [lastPortDay, setLastPortDay] = useState('none found');
    const [warn, setWarn] = useState();

    useEffect(() => {
        const fetchLastPortDay = async () => {
          console.log("lastport");
            try {
              const responseData = await sendRequest(
                `https://api.docked.co/api/port-day/ship-lastport`,
                'POST',
                JSON.stringify({
                shipId: shipId
                }),
                {
                  'Content-Type': 'application/json',
                  Authorization: auth.token
                }

              );
             
              if (responseData) {
                console.log(responseData);
                setLastPortDay(responseData.lastPortDay[0]);

                if (responseData.lastPortDay.length > 0) {
                  let lastDate = new Date(responseData.lastPortDay[0].portDate);
                  let today = new Date()
                  let difference = daysBetween(lastDate, today)

                  if (difference > 180) {
                      setWarn("last-day-warn")
                  } 
                }

                


              }
             
            } catch (err) {
                console.log(err);
            }
          };
          fetchLastPortDay();
    
        
    }, [sendRequest, shipId]);

    function daysBetween(date1, date2) {

      const ONE_DAY = 1000 * 60 * 60 * 24;
      const differenceMs = Math.abs(date1 - date2);
      return Math.round(differenceMs / ONE_DAY);
    }

    return (
        <p className={`ship-last-port ${warn}`}>

        {!error && isLoading && 'checking...'}
        {error && error}
        {!error && !lastPortDay && 'No port days found'}
        {lastPortDay && !error && !isLoading && `${moment(lastPortDay.portDate).format('LL')}`}

        
        </p>
    )




}

export default LastPortDay;