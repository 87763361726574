import React, { useState, useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/en-gb';

import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import Spinner from '../UIElements/LoadingSpinner';
import './EditPortDayForm.css';
import ChoosePort from './PortSelecter/PortSelect';

const EditPortDayForm = ({ portDay, update, getPosition, cancel }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { register, handleSubmit, errors } = useForm();
  const [loadedPortDay, setLoadedPortDay] = useState();
  moment.locale('en-gb');

  useEffect(() => {
    if (portDay) {
      setLoadedPortDay(portDay);
    }
  }, [portDay]);

  const onSubmit = async () => {
    
    getPosition()
    clearError();

    try {
      const response = await sendRequest(
        `https://api.docked.co/api/port-day/update/${portDay.id}`,
        'PATCH',
        JSON.stringify({
          portId: loadedPortDay.portId,
          shipId: loadedPortDay.shipId,
          portDate: loadedPortDay.portDate,
          arrivalTime: loadedPortDay.arrivalTime,
          departureTime: loadedPortDay.departureTime,
          portDayType: loadedPortDay.portDayType,
          overnight: loadedPortDay.overnight,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);

     
      update();
      
    } catch (err) {
      console.log(err);
    }
  };

  const deletePort = async id => {

    getPosition()

    try {
         await sendRequest(
          `https://api.docked.co/api/port-day/delete/${portDay.id}`,
          'DELETE',
          {
            Authorization: 'Bearer ' + auth.token,
          }
        );
        
        
        update();
       
      } catch (err) {
        console.log(err);
      }

  }

  const updateDateHandler = e => {
    const newDate = moment(e.target.value).format();
    const updatedLoadedPortDay = { ...loadedPortDay, portDate: newDate };
    setLoadedPortDay(updatedLoadedPortDay);
  };

  const updateArrivalHandler = e => {
    const newArrival = e.target.value;
    const updatedLoadedArrival = { ...loadedPortDay, arrivalTime: newArrival };
    setLoadedPortDay(updatedLoadedArrival);
  };

  const updateDepartHandler = e => {
    const newDepart = e.target.value;
    const updatedLoadedDepart = { ...loadedPortDay, departureTime: newDepart };
    setLoadedPortDay(updatedLoadedDepart);
  };

  const updateTypeHandler = e => {
    const newType = e.target.value;
    console.log(newType);
    const updatedLoadedType = { ...loadedPortDay, portDayType: newType };
    setLoadedPortDay(updatedLoadedType);
  };

  const updatePortHandler = data => {
      console.log(data);
    const port = data;
    const updatedLoadedPort = { ...loadedPortDay, portId: port.id };
    setLoadedPortDay(updatedLoadedPort);
  };

  return (
    <section id="edit-portday-form">
      <h1>Edit Port Day</h1>
      {error && <p className="text-error port-day-edit-warning">{error}</p>}
      {isLoading && <Spinner />}
      {loadedPortDay && !isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-grouping">

          <div className="input-with-error__wrapper">
            <Input
              id="portDate"
              name="portDate"
              type="date"
              groupFront="Port Date:"
              refs={register({ required: true, minLength: 5 })}
              value={moment(loadedPortDay.portDate).format('YYYY-MM-DD')}
              onChange={updateDateHandler}
            />
            </div>
            <div className="input-with-error__wrapper input-margin-left">

              <Select  
                groupFront="Port Type:" 
                name="type" 
                id="type" 
                value={loadedPortDay.portDayType}
                onChange={updateTypeHandler}
                >
                <option value="TRAN">In Transit</option>
                <option value="TRDA">Turnround Day Arrival</option>
                <option value="TRDD">Turnround Day Departing</option>
                <option value="TNRD">Turnround Day</option>
                <option value="OVNT">Overnight</option>
              </Select>
            </div>
          </div>

          <div className="input-grouping">
            <div className="input-with-error__wrapper">
              <label>
                <span className="inputError">
                  {errors.arrivalTime &&
                    errors.arrivalTime.type === 'required' &&
                    'departure time cannot be empty'}
                  {errors.arrivalTime &&
                    errors.arrivalTime.type === 'minLength' &&
                    'check format, eg --:--:-- or 08:00:00'}
                </span>
              </label>
              <Input
                id="arrivalTime"
                name="arrivalTime"
                type="text"
                groupFront="Arrival Time:"
                refs={register({ required: true, minLength: 8 })}
                value={loadedPortDay.arrivalTime}
                onChange={updateArrivalHandler}
              />
            </div>
            <div className="input-with-error__wrapper input-margin-left">
              <label>
                <span className="inputError">
                  {errors.departTime &&
                    errors.departTime.type === 'required' &&
                    'arrival time cannot be empty'}
                  {errors.departTime &&
                    errors.departTime.type === 'minLength' &&
                    'check format, eg --:--:-- or 08:00:00'}
                </span>
              </label>
              <Input
                id="departTime"
                name="departTime"
                type="text"
                groupFront="Departure Time:"
                refs={register({ required: true, minLength: 8 })}
                value={loadedPortDay.departureTime}
                onChange={updateDepartHandler}
              />
            </div>
          </div>

          <ChoosePort portSelected={updatePortHandler} />




          <div className="update-port-day-actions">
            <Button className="button-primary" type="submit" title="submit" />
            <Button
              className="button-secondary"
              type="button"
              title="cancel"
              onClick={cancel}
            />
            <Button className="button-danger" type="button" title="delete" onClick={()=>deletePort(loadedPortDay.id)} />
          </div>
        </form>
      )}
    </section>
  );
};

export default EditPortDayForm;
