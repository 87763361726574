import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ItineraryRow.css';

const ItineraryRow  = ({portDay, selected}) => {

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
      }

    return (
        <tr className='port-day-row' onClick={() => {selected(portDay)}}>
             <td className='port-day-date'>{formatDate(portDay.portDate)}</td>
             <td className='port-day-name'>{portDay.port?.portName}</td>
             <td className='port-day-country'><div className='itinerary-row-country'>{portDay.port?.country} <img src={`https://docked.co/images/flags/${portDay.port?.country.replace(/\s/g, '-')}.png`} alt={portDay.port?.country} /></div></td>
             <td className='port-day-arr'>{portDay.arrivalTime}</td>
             <td className='port-day-dep'>{portDay.departureTime}</td>
             <td className='port-day-type'>{portDay.portDayType}</td>
              <td className='port-day-update'>{formatDate(portDay.updatedAt)}</td>
              <td className='port-day-create'>{formatDate(portDay.createdAt)}</td>
        </tr>
    );

}

export default ItineraryRow;