import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { useHttpClient } from '../../shared/hooks/http-hook';
import Input from '../../shared/components/FormElements/Input';
import Card from '../../shared/components/UIElements/Card';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ShipItem from './ShipItem';

import './ShipList.css';

const ShipList = ( {selectShipHandler}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [ships, setShips] = useState([]);

  useEffect(() => {
  
      const fetchShips = async () => {
        try {
          const responseData = await sendRequest(
            `https://api.docked.co/api/ships/`
          );
          const ships = _.orderBy(responseData.ships,  'companyId');
          setShips(ships);
          console.log(ships);
         
        } catch (err) {
          console.log(err);
        }
      };
      fetchShips();
    
  }, [sendRequest]);

  const [shipList, setShipList] = useState([]);

  useEffect(() => {
    setShipList(ships);
  }, [ships]);

  const submitHandler = event => {
    event.preventDefault();
    let result;

    if (event.target.name.value) {
      if (result) {
        const update = ships.filter(s =>
          s.name.includes(event.target.name.value)
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = ships.filter(s => s.name.includes(event.target.name.value));
      }
    }

    if (event.target.companyName.value) {
      if (result) {
        const update = result.push(
          ships.filter(s =>
            s.company.name.includes(event.target.companyName.value)
          )
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = ships.filter(s =>
          s.company.name.includes(event.target.companyName.value)
        );
      }
    }

    if (!result) {
      setShipList(ships);
    } else if (result.length > 0) {
      setShipList(result);
    }
  };

  const selectHandler = id => {
    selectShipHandler(id)
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading ? 
      <Card className={'ship-list-card'}>
        <div className="ship-list-content">
          <div className="ship-list-filter">
            <form onSubmit={submitHandler}>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="ship name"
                className="search-input  no-group"
                wrapperClassName="input-search-wrapper"
              />
              <Input
                id="companyName"
                name="companyName"
                type="text"
                placeholder="company name"
                className="search-input  no-group"
                wrapperClassName="input-search-wrapper"
              />

              <button className="search-button" type="submit">
                go
              </button>
            </form>
          </div>
          <div className="ship-list">
            {shipList.length === 0 ? (
              <h2>No Ships found</h2>
            ) : (
              shipList.map(ship => (
                <ShipItem
                  key={ship.id}
                  id={ship.id}
                  name={ship.name}
                  company={ship.company.name}
                  profileImageUrl={ship.profileImageUrl}
                  companyName={ship.companyName}
                  updated={ship.updatedAt}
                  selectShip={selectHandler}
                />
              ))
            )}
          </div>
        </div>
      </Card>
      : null }
    </>
  );
};

export default ShipList;
