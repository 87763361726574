import React, { useContext, Fragment, useState, useEffect } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Avatar from '../../shared/components/UIElements/Avatar';
import Button from '../../shared/components/UIElements/Buttons/Button';

import EditProfileAvatar from './EditProfileImage';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import UpdateStaffDetails from './UpdateAdminDetails';
import './MyAccountSettings.css';
import UpdateAdminPassword from './UpdateAdminPassword';

const MyAccountSettings = props => {

  const { REACT_APP_API_URL } = process.env;
  const { isLoggedIn, token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUser, setLoadedUser] = useState();
  const [avatarSrc, setAvatarSrc] = useState();

  const [showEditAvatar, setShowEditAvatar] = useState();

  useEffect(() => {

    const getUser = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/admin/${userId}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }
        );

        if (response.admin) {
            setLoadedUser(response.admin);
          setAvatarSrc(response.admin.profileImageUrl);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [isLoggedIn]);

  const updateAvatarStateHandler = data => {
    setShowEditAvatar(false);
    setAvatarSrc(data.profileImageUrl);
  };

  return (
    <Fragment>
      <section id="my-account-settings">
        <div className="my-account-settings-content">
          <h1 className="my-account-settings-title">My Account Settings</h1>
          <div className="my-account-settings-inner-content">
            <div className="avatar-settings-container">
              <Avatar src={avatarSrc} />
              <Button title="edit" onClick={() => setShowEditAvatar(true)} />
            </div>
            <div className="personal-settings-container">
             <UpdateStaffDetails user={loadedUser} />
            </div>
            <div className="personal-settings-container">
             <UpdateAdminPassword user={loadedUser} />
            </div>
          </div>
        </div>
      </section>
      <ErrorModal error={error} onClear={clearError} />

      <Modal show={showEditAvatar} hideButton={true}>
        <div className="update-avatar-modal__wrapper">
          <h1>Update Avatar</h1>
          <EditProfileAvatar
            userId={userId}
            setImage={updateAvatarStateHandler}
          />
          <Button
            className="button-secondary"
            title="cancel"
            onClick={() => setShowEditAvatar(false)}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default MyAccountSettings;
