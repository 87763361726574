import React, {useEffect, useState} from 'react';
import './CompanyBadge.css';
import CompanyLogo from './CompanyLogo';


const CompanyBadge = ({ companyName }) => {

    return (
       
       <div className='company-badge'>
       <CompanyLogo className='badge-img' src={companyName} alt={companyName} />
       <h2>{companyName}</h2>

       </div>

    )
}

export default CompanyBadge;