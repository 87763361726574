import React from 'react';
import CompanyLogo from '../../shared/components/UIElements/CompanyLogo';
import LastPortDay from './LastPortDay';



import './ShipItem.css';


const ShipItem = props => {


   

    const updated = new Date(props.updated).toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'gmt'
        }
      ); 

    return (  
        <div className='ship-item__wrapper' onClick={() => props.selectShip(props.id)} to={`/ship/${props.id}`}>
            <div className='ship-item-avatar'>
                <CompanyLogo src={props.company} alt={props.company} />
            </div>
            <div className='ship-item-data'>
                    <h2 className='ship-item-name'>{props.name}</h2>
                <div className='ship-item-id__wrapper'>
                    <h3 className='ship-item-label'>ID: </h3>
                    <h3 className='ship-item-id'>{props.id}</h3>
                </div>
                <div className='ship-item-email__wrapper'>
                    <h3 className='ship-item-label'>Company:</h3>
                    <p className='ship-item-email'>{props.company}</p>
                </div>
                <div className='ship-item-joined__wrapper'>
                    <p className='ship-item-label joined-label'>last port day:</p>
                    <p className='ship-item-joined'><LastPortDay shipId={props.id} /></p>
                </div>
                <div className='ship-item-joined__wrapper'>
                    <p className='ship-item-label joined-label'>Updated:</p>
                    <p className='ship-item-joined'>{updated}</p>
                </div>
                
               
            </div>
        </div>
    )
}

export default ShipItem;