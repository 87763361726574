import React, { Fragment, useState, useContext } from 'react'
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import './UpdateAdminDetails.css';

const UpdateStaffPassword = ({user}) => {

    const { REACT_APP_API_URL } = process.env;
  const { token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { register, handleSubmit, errors } = useForm();
  const [noMatch, setNoMatch] = useState(false);
  const [newPass, setNewPass] = useState();
  const [success, setSuccess] = useState();

    
      const onSubmit = async data => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/admin/manual-pass-update`,
            'PATCH',
            JSON.stringify({
              userId: userId,
              oldPass: data.currentPassword,
              newPass: data.passConfirm,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
          if (response.message)
          setSuccess(response.message)
        } catch (err) {
          //console.log(err);
        }
      };

      const newPassforTest = e => {
        setNewPass(e.target.value);
      }

      const comparePass = e => {
        if (e.target.value !== newPass) {
          setNoMatch(true);
        } else {
          setNoMatch(false);
        }
      }


    return (
        <Fragment>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && (
            <div className="center spinner-margin-top">
              <Spinner 
                className='spinner-primary' 
                classNameText='spinner-text-primary'
                message='updating'
              />
            </div>
          )}
          {!isLoading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span className="inputError">
                  {errors.firstName &&
                    errors.firstName.type === 'required' &&
                    'Current password required!'}
                  {errors.firstName &&
                    errors.firstName.type === 'minLength' &&
                    'At least 8 characters are required!'}
                </span>
              </label>
              <Input
                id="currentPassword"
                name="currentPassword"
                type="password"
                groupFront="current password"
                refs={register({ required: true, minLength: 2 })}
              />

              <label>
                <span className="inputError">
                  {errors.password &&
                    errors.password.type === 'required' &&
                    'Last name is required'}
                  {errors.password &&
                    errors.password.type === 'minLength' &&
                    'At least 8 characters are required!'}
                </span>
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                groupFront="new password"
                refs={register({ required: true, minLength: 8 })}
                onChange={newPassforTest}
              />

              <label>
                <span className="inputError">
                {noMatch && 'Passwrds don\'t match!'}
                  {errors.passConfirm &&
                    errors.passConfirm.type === 'required' &&
                    'password confirmation is required'}
                </span>
              </label>
              <Input
                id="passConfirm"
                inputClassName={noMatch && `invaild`}
                type="password"
                name="passConfirm"
                groupFront="confirm password"
                refs={register({ required: true, minLength: 8 })}
                onChange={comparePass}
              />
              <p className="personal-settings-container-p">Must contain at least 8 characters</p>

              {success ? <h1>{success}</h1> 
              :
              <Button
                type="submit"
                className="button-primary personal-update-btn"
                title="Update Password"
              />}
            </form>
          )}
          </Fragment>
    )
}

export default UpdateStaffPassword;