import React, {Suspense,Fragment} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Dashboard from './dashboard/pages/dashboard';
import Ports from './Ports/pages/Ports';
import Ships from './Ships/pages/Ships';
import Itineraries from './Itineraries/pages/Itineraries';
import Auth from './auth/pages/Auth';
import Forgot from './auth/pages/ForgotPassword';
import Reset from './auth/pages/ResetPassword';
import Settings from './settings/pages/Settings';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle
);

function App() {

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  let routes;

  if (!token) {
    routes = (
     
          <Switch>
            <Route path="/auth" exact>
              <Auth />
            </Route>
            <Route path="/forgot" exact>
              <Forgot />
            </Route>
            <Route path="/reset/:token">
            <Reset />
          </Route>
          <Route path="*" >
              <Auth />
            </Route>
          </Switch>
     
    );
  } else {
    routes = (
      <Fragment>
        <SideBar />
          <Switch>
          <Route path="/" exact>
              <Dashboard />
            </Route>
            <Route path="/ports" exact>
              <Ports />
            </Route>
            <Route path="/ships" exact>
              <Ships />
            </Route>
            <Route path="/itineraries" exact>
              <Itineraries />
            </Route>
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="*" >
              <Dashboard />
            </Route>
          </Switch>
      </Fragment>
    );
  }




  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl : userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
       
        <main>
          <Suspense 
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;



