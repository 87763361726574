import React, {useState} from 'react';

import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import PortDetail from '../components/PortDetail';
import PortList from '../components/PortList';

import './Ports.css';

const portsMenu = [
  {
    id: 0,
    title: 'list'
  },
  {
    id: 1,
    title: 'add Port'
  }
]

const Ports = props => {

  const [showList, setShowList] = useState(true);
  const [showAddPort, setShowAddPort] = useState(false);
  const [showPort, setShowPort] = useState(true);
  const [portId, setPortId] = useState();

  const selectPortHandler = id => {
    setShowPort(true);
    setPortId(id);
    console.log(id);
    setShowList(false);
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowPort(false);
        setShowAddPort(false);
        setShowList(true);        
        break;
      case 1:
        setShowPort(false);
          setShowAddPort(true);
          setShowList(false);        
        break;
    
      default:
        break;
    }
  }







  return (
    <div className="page__wrapper">
    <Header title={'Ports'} />
    <SubMenu items={portsMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
    {showPort && portId && <PortDetail portId={portId} /> }
    {showList && <PortList selectShipHandler={selectPortHandler}/> }
    {showAddPort && <h1>Add New Port</h1>}
    </div>
  </div>
  );
};

export default Ports;
