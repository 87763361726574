import React, { useEffect, useState } from 'react';

import LoadingSpinner from '../UIElements/LoadingSpinner';
import { useHttpClient } from '../../hooks/http-hook';
import Button from '../UIElements/Buttons/Button';
import './ItineraryUpdater.css';

const ItineraryUpdater = props => {
  const { isLoading, sendRequest } = useHttpClient();
  const [showGetData, setShowGetData] = useState(true);
  const [showClean, setShowClean] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [message, setMessage] = useState('ready');
  const [shipName, setShipName] = useState();
  const [error, setError] = useState();
  const [month, setMonth] = useState("--");
  const [year, setYear] = useState('----')

  const GetDataHandler = event => {
    event.preventDefault();
    console.log(shipName);
    console.log(event.target.month.value);
    console.log(event.target.year.value);
    console.log(props.shipId);

    setMonth(event.target.month.value);
    setYear(event.target.year.value);
    const getData = async () => {
      try {
        const responseData = await sendRequest(
          `https://updater.docked.co/api/scrape/cruise-timetable`,
          'POST',
          JSON.stringify({
            shipId: props.shipId,
            month: event.target.month.value,
            year: event.target.year.value,
            shipName: shipName
          }),
          {
            'Content-Type': 'application/json',
          }
        );
        console.log(responseData);
        setMessage(responseData.message);
        setShowGetData(false);
        setShowClean(true);

      } catch (err) {
        setError(err);
        console.log(err);
      }
    };
    getData();
  };


    const cleanData = async () => {
        console.log('clean');
        try {
            const responseData = await sendRequest(
              `https://updater.docked.co/api/scrape/clean`
              
            );
            console.log(responseData);
            setMessage(`Cleaned ${responseData.cleanPortDays.length} port Days, standing By to update Live database`);
            setShowClean(false);
            setShowUpdate(true);
    
          } catch (err) {
            setError(err);
            console.log(err);
          }
    
      }
   



  const updateData = async () => {
    try {
        const responseData = await sendRequest(
          `https://updater.docked.co/api/scrape/update`
        );
        console.log(responseData);
        setMessage(responseData.message);
        setShowGetData(true);
        setShowUpdate(false);
        if(error) {
            setError(null);
        }

      } catch (err) {
        setError(err);
        console.log(err);
      }

  }

  useEffect(() => {
    setShipName(props.shipName.toLowerCase().replace(/\W/g, ''));
  }, []);

  return (
    <section id="updater">
    <h2>Itinerary Updater</h2>
      <div className={'updater-spinner__wrapper'}>
        {isLoading && <LoadingSpinner />}
      </div>

      <div className="updater-actions">
        {showGetData && !isLoading && (
          <form className="updater-form" onSubmit={GetDataHandler}>
            <div className={`input-wrapper get-data-input`}>
              <div className={`form-group`}>
                <span>Month</span>
                <select name="month" id="month" className="form-field">
                  <option value="jan">Jan</option>
                  <option value="feb">Feb</option>
                  <option value="mar">Mar</option>
                  <option value="apr">Apr</option>
                  <option value="may">May</option>
                  <option value="jun">Jun</option>
                  <option value="jul">Jul</option>
                  <option value="aug">Aug</option>
                  <option value="sep">Sep</option>
                  <option value="oct">Oct</option>
                  <option value="nov">Nov</option>
                  <option value="dec">Dec</option>
                </select>
              </div>
            </div>
            <div className={`input-wrapper  get-data-input`}>
              <div className={`form-group`}>
                <span>Year</span>
                <select name="year" id="year" className="form-field">
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>
            </div>
            <div className="get-data-actions">
              <Button
                className="get-data-btn"
                title="Get Data"
                type="onSubmit"
              />
            </div>
          </form>
        )}
        {showClean && !isLoading && <Button title="Clean Data" onClick={() => cleanData()} />}
        {showUpdate && !isLoading && <Button title="Update Data" onClick={updateData}/>}
      </div>
      <div className="updater-message">
      <p className="updater_last-update">Last update date: {month}/{year}</p>
       {!error && <p>{message}</p>}
        {error &&  <p className='updater-error-message'>{error.message}</p>}
      </div>
    </section>
  );
};

export default ItineraryUpdater;
