import React from 'react';
import { useForm } from 'react-hook-form';

import ImageUpload from '../../shared/components/FormElements/GeneralImageUpload';
import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './EditProfileImage.css';

const EditProfileAvatar = ({ userId, setImage }) => {

  const { REACT_APP_API_URL } = process.env;
  const { register, handleSubmit, errors } = useForm();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const onSubmit = async data => {
    try {
      //console.log(data.image[0]);
      const formData = new FormData();
      formData.append('avatar', data.image[0]);
      formData.append('userId', userId);

      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/admin/avatar`,
        'PATCH',
        formData
      );

      console.log(response.result);

      if (response.result) {
        setImage(response.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id="content-image-upload__wrapper">
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)} className="content-image-upload-form">
          <label>
            <span className="inputError">
              {errors.image &&
                errors.image.type === 'required' &&
                'You must provide an image'}
            </span>
          </label>
          <ImageUpload id="image" refs={register({ required: true })} />
          <Button
            type="submit"
            className="button-secondary upload-gpimg-btn"
            title="upload"
          />
          <span className="upload-rules">
            images must be either .png .jpeg & less than 10MB
          </span>
        </form>
      )}
    </section>
  );
};

export default EditProfileAvatar;
