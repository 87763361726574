import React from 'react';




import '../../Ships/components/ShipItem.css';


const portItem = props => {


    var flag = props.country.replace(" ", "-");

    const updated = new Date(props.updated).toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'gmt'
        }
      ); 

    return (  
        <div className='ship-item__wrapper' onClick={() => props.selectPort(props.id)} to={`/port/${props.id}`}>
            <div className='ship-item-avatar'>
               <img src={`https://docked.co/images/flags/${flag}.png`}  alt='flag' height='48px'/>
            </div>
            <div className='ship-item-data'>
                    <h2 className='ship-item-name'>{props.name}</h2>
                
                <div className='ship-item-email__wrapper'>
                    <h3 className='ship-item-label'>Country:</h3>
                    <p className='ship-item-email'>{props.country}</p>
                </div>
                <div className='ship-item-joined__wrapper'>
                    <p className='ship-item-label joined-label'>Updated:</p>
                    <p className='ship-item-joined'>{updated}</p>
                </div>
                
               
            </div>
        </div>
    )
}

export default portItem;