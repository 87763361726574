import React from 'react';

import Header from '../../shared/components/header/Header';
const Dashboard = props => {
  return (
    <div className="page__wrapper">
      <Header title={'Dashboard'} />
      <div className="page-content"></div>
    </div>
  );
};

export default Dashboard;
