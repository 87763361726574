import React, { useEffect, useState, useContext, useRef} from 'react';

import Itinerary from '../../shared/components/Itinerary/ItineraryList';
import Button from '../../shared/components/UIElements/Buttons/Button';
import CompanyBadge from '../../shared/components/UIElements/CompanyBadge';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ItineraryUpdater from '../../shared/components/Updater/ItineraryUpdater';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import EditPortDayForm from '../../shared/components/Forms/EditPortDayForm';
import LastPortDay from './LastPortDay';
import './ShipDetail.css';

const ShipDetail = ({ shipId, closeDetail }) => {
    const itineraryScrollPosition = useRef();
    const auth = useContext(AuthContext);
    const { isLoading, sendRequest } = useHttpClient();
    const [ship, setShip] = useState();
    const [itineraryParams, setItineraryParams] = useState();
    const [reloadItinerary, setReloadItinerary] = useState();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showScrapWarn, setShowScrapWarn] = useState(false);


    const scrapShip = async () => {

        try {
            await sendRequest(
              `https://api.docked.co/api/ships/remove/${shipId}`,
              'DELETE',
              JSON.stringify({
                shipId: shipId
                }),
                {
                  'Content-Type': 'application/json',
                  Authorization: auth.token
                }
              );
            setShowScrapWarn(false)
            closeDetail()
              console.log('deleted');
            
          } catch (err) {
            console.log(err);
          }
    }

    useEffect(() => {

          const fetchShip = async () => {
            try {
              const responseData = await sendRequest(
                `https://api.docked.co/api/ships/${shipId}`
                );
              setShip(responseData.ship);
              console.log(responseData.ship);
              setItineraryParams({shipId:shipId, startDate:'', endDate:''});
            } catch (err) {
              console.log(err);
            }
          };
          fetchShip();
          
      }, [sendRequest, shipId, ]);  

     

      const setPosition = () => {
          console.log('try to set position');
          console.log(scrollPosition);
          itineraryScrollPosition.current.scrollTop = scrollPosition
      }

      const getPosition = () => {
        console.log('get position');
          setScrollPosition(itineraryScrollPosition.current.scrollTop)
          console.log(itineraryScrollPosition.current.scrollTop)
      }

return (
    <>

    <Modal show={showScrapWarn} hideButton={true} >
    <div className='scrap-warning-wrapper'>
        <h1>Scrap Ship?</h1>
        <p>Are you sure?, this is irriversable and will remove all its port day data</p>
        <div className='scrap-warning-actions'>
            <Button className='button-secondary' title='cancel' onClick={()=>setShowScrapWarn(false)} />
            <Button className='button-danger' title='scrap' onClick={scrapShip} />
        </div>
    </div>
       
    </Modal>

        <section id='ship-detail' ref={itineraryScrollPosition} > 
        {isLoading && <LoadingSpinner /> }
        {ship ?
            <div className='ship-detail-info__wrapper'>
                <div className='ship-detail-box ship-image'>
                    {/* ship thumb element */}
                    <div className='ship-detail-placeholder'></div>
                </div>
                <div className='ship-detail-box ship-title'>
                    <h1>{ship.name}</h1>
                    <div className='company-badge'>
                        <CompanyBadge companyName={ship.company.name} />
                    </div>
                    <ItineraryUpdater shipName={ship.name} shipId={ship.id} />
                </div>
                <div className='ship-detail-box ship-info'>
                <h2 className='ship-info-title'>Vessel Data</h2>
                    <div className='ship-detail-info-text'>
                        <p>Year: <span className='primary'>{ship.built}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>tonnage: <span className='primary'>{ship.tonnage}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>Speed: <span className='primary'>{ship.speed}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>Length: <span className='primary'>{ship.length}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>Beam: <span className='primary'>{ship.beam}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>Crew: <span className='primary'>{ship.crew}</span></p>
                    </div>
                    <div className='ship-detail-info-text'>
                        <p>PAX: <span className='primary'>{ship.passengers}</span></p>
                    </div>
                </div>
                <div className='ship-detail-box ship-today'>
                    <h1>Actions</h1>
                    <Button className='less-margin'title='Reload Itinerary' onClick={() => {reloadItinerary ?  setReloadItinerary(false) : setReloadItinerary(true)} }/>
                    <Button className='button-secondary less-margin'title='Edit Ship' />
                    <Button className='button-danger less-margin' title='Scrap Ship' onClick={()=>setShowScrapWarn(true)} />
                    <h1>Info</h1>
                    <p>Last port day in database: <LastPortDay shipId={shipId}/></p>
                    <p>Updater last run on:</p>
                </div>
            </div>
            : null }
            <div className='ship-detail-itinerary__wrapper'>
                <Itinerary 
                    params={itineraryParams} 
                    reload={reloadItinerary} 
                    getPosition={getPosition} 
                    scroll={setPosition}  
                />
            </div>
            
        </section>
    </>
);

}

export default ShipDetail;