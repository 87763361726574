import React from 'react';

import Header from '../../shared/components/header/Header';
import './Itineraries.css';

const Itineraries = props => {
  return (
    <div className="page__wrapper">
    <Header title={'Itineraries'} />
    <div className="page-content"></div>
  </div>
  );
};

export default Itineraries;
