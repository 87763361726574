import React, { useState, useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import './AddShip.css';
import CompanyItem from './companyItem';
import Button from '../../shared/components/UIElements/Buttons/Button';

const AddShip = ({update}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const { register, handleSubmit, errors } = useForm();

  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState('no selection');
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.docked.co/api/companies/`
        );
        setCompanies(responseData.companies);
        console.log(responseData.companies);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompanies();
  }, [sendRequest]);

  const selected = company => {
    setSelectedCompany(company.name);
    setSelectedCompanyId(company.id);
    console.log(selectedCompanyId);
  };

  const onSubmit = async data => {

    clearError();

    try {
      const response = await sendRequest(
        `https://api.docked.co/api/ships/create-ship`,
        'POST',
        JSON.stringify({
          name: data.name,
          companyId: selectedCompanyId,
          built: data.built,
          tonnage: data.tonnage,
          speed: data.speed,
          length: data.length,
          beam: data.beam,
          crew: data.crew,
          passengers: data.passengers,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);

      update();
    } catch (err) {
      console.log(err);
    }





  };

  return (
    <section id="add-ship">
      <h1>Add New Ship</h1>
      <div className="add-ship-company-list">
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && (
          <div className="center spinner-margin-top">
            <LoadingSpinner />
          </div>
        )}
        {companies &&
          companies.map(company => (
            <CompanyItem
              key={company.id}
              company={company}
              selected={selected}
            />
          ))}
      </div>
      <h2>{selectedCompany}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <label>
              <span className="inputError">
                {errors.name &&
                  errors.name.type === 'required' &&
                  'ships name cannot be empty'}
                {errors.name && errors.name.type === 'minLength'}
              </span>
            </label>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="required"
              groupFront="Ship Name:"
              refs={register({ required: true, minLength: 5 })}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="built"
              name="built"
              type="text"
              placeholder="optional"
              groupFront="Build Year:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="tonnage"
              name="tonnage"
              type="text"
              placeholder="optional"
              groupFront="Tonnage:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="speed"
              name="speed"
              type="text"
              placeholder="optional"
              groupFront="Speed:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="beam"
              name="beam"
              type="text"
              placeholder="optional"
              groupFront="Beam:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="length"
              name="length"
              type="text"
              placeholder="optional"
              groupFront="Length:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="crew"
              name="crew"
              type="text"
              placeholder="optional"
              groupFront="Crew:"
              refs={register()}
            />
          </div>
        </div>

        <div className="input-grouping">
          <div className="input-with-error__wrapper">
            <Input
              id="passengers"
              name="passengers"
              type="text"
              placeholder="optional"
              groupFront="Passengers:"
              refs={register()}
            />
          </div>
        </div>

        <div className="update-port-day-actions">
          <Button className="button-primary" type="submit" title="submit" />
          <Button className="button-secondary" type="button" title="cancel" />
        </div>
      </form>
    </section>
  );
};

export default AddShip;
