import React, { useState, useContext } from 'react';

import { useForm } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/en-gb';

import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import Spinner from '../UIElements/LoadingSpinner';
import './EditPortDayForm.css';
import ChoosePort from './PortSelecter/PortSelect';

const AddPortDayForm = ({ shipId, update }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { register, handleSubmit, errors } = useForm();
  const [loadedPortDay, setLoadedPortDay] = useState();
  moment.locale('en-gb');

  const onSubmit = async (data) => {
    clearError();

    console.log(shipId);
    console.log(data);
    console.log(loadedPortDay.portId);

    try {
      const response = await sendRequest(
        `https://api.docked.co/api/port-day/create-port-day`,
        'POST',
        JSON.stringify({
          portId: loadedPortDay.portId,
          shipId: shipId,
          portDate: data.portDate,
          arrivalTime: data.arrivalTime,
          departureTime: data.departTime,
          portDayType: data.type,
          overnight: data.overnight,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);

      update(response.portDay);
    } catch (err) {
      console.log(err);
    }
  };


  const updatePortHandler = data => {
      console.log(data);
    const port = data;
    const updatedLoadedPort = { ...loadedPortDay, portId: port.id };
    setLoadedPortDay(updatedLoadedPort);
  };

  return (
    <section id="edit-portday-form">
      <h1>Add Port Day</h1>
      {error && <p className="text-error port-day-edit-warning">{error}</p>}
      {isLoading && <Spinner />}
      
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-grouping">

          <div className="input-with-error__wrapper">
            <Input
              id="portDate"
              name="portDate"
              type="date"
              groupFront="Port Date:"
              refs={register({ required: true, minLength: 5 })}
              
            />
            </div>
            <div className="input-with-error__wrapper input-margin-left">

              <Select  
                groupFront="Port Type:" 
                name="type" 
                id="type" 
                refs={register({ required: true})}
                >
                <option value="TRAN">In Transit</option>
                <option value="TRDA">Turnround Day Arrival</option>
                <option value="TRDD">Turnround Day Departing</option>
                <option value="TNRD">Turnround Day</option>
                <option value="OVNT">Overnight</option>
              </Select>
            </div>
          </div>

          <div className="input-grouping">
            <div className="input-with-error__wrapper">
              <label>
                <span className="inputError">
                  {errors.arrivalTime &&
                    errors.arrivalTime.type === 'required' &&
                    'departure time cannot be empty'}
                  {errors.arrivalTime &&
                    errors.arrivalTime.type === 'minLength' &&
                    'check format, eg --:--:-- or 08:00:00'}
                </span>
              </label>
              <Input
                id="arrivalTime"
                name="arrivalTime"
                type="text"
                groupFront="Arrival Time:"
                refs={register({ required: true, minLength: 8 })}
                placeholder="--:--:--"
              />
            </div>
            <div className="input-with-error__wrapper input-margin-left">
              <label>
                <span className="inputError">
                  {errors.departTime &&
                    errors.departTime.type === 'required' &&
                    'arrival time cannot be empty'}
                  {errors.departTime &&
                    errors.departTime.type === 'minLength' &&
                    'check format, eg --:--:-- or 08:00:00'}
                </span>
              </label>
              <Input
                id="departTime"
                name="departTime"
                type="text"
                groupFront="Departure Time:"
                refs={register({ required: true, minLength: 8 })}
                placeholder="--:--:--"
              />
            </div>
          </div>

          <ChoosePort portSelected={updatePortHandler} />




          <div className="update-port-day-actions">
            <Button className="button-primary" type="submit" title="submit" />
            <Button
              className="button-secondary"
              type="button"
              title="cancel"
              onClick={update}
            />
            
          </div>
        </form>
      
    </section>
  );
};

export default AddPortDayForm;
