import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Input from '../../shared/components/FormElements/Input';
import Card from '../../shared/components/UIElements/Card';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';


import '../../Ships/components/ShipList.css';
import PortItem from './PortItem';

const PortList = ( {selectShipHandler}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [ports, setPorts] = useState([]);
  const [portList, setPortList] = useState([]);

  useEffect(() => {
  
      const fetchPorts = async () => {
        try {
          const responseData = await sendRequest(
            `https://api.docked.co/api/ports/`,
            'POST'
          );
          
          const ports = _.orderBy(responseData.ports,  'country');
          setPorts(ports);
          console.log(ports);
        } catch (err) {
          console.log(err);
        }
      };
      fetchPorts();
    
  }, [sendRequest]);

  

  useEffect(() => {
    setPortList(ports);
  }, [ports]);

  const submitHandler = event => {
    event.preventDefault();
    let result;

    if (event.target.name.value) {
      if (result) {
        const update = ports.filter(p =>
          p.portName.includes(event.target.name.value)
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = ports.filter(p => p.portName.includes(event.target.name.value));
      }
    }

    if (event.target.country.value) {
      if (result) {
        const update = result.push(
            ports.filter(p =>
            p.country.includes(event.target.country.value)
          )
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = ports.filter(p =>
          p.country.includes(event.target.country.value)
        );
      }
    }

    if (!result) {
      setPortList(ports);
    } else if (result.length > 0) {
      setPortList(result);
    }
  };

  const selectHandler = id => {
    selectShipHandler(id)
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading ? 
      <Card className={'ship-list-card'}>
        <div className="ship-list-content">
          <div className="ship-list-filter">
            <form onSubmit={submitHandler}>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="port name"
                className="search-input  no-group"
                wrapperClassName="input-search-wrapper"
              />
              <Input
                id="country"
                name="country"
                type="text"
                placeholder="country"
                className="search-input  no-group"
                wrapperClassName="input-search-wrapper"
              />

              <button className="search-button" type="submit">
                go
              </button>
            </form>
          </div>
          <div className="ship-list">
            {portList.length === 0 ? (
              <h2>No Ports found</h2>
            ) : (
              portList.map(port => (
                <PortItem
                  key={port.id}
                  id={port.id}
                  name={port.portName}
                  country={port.country}
                  updated={port.updatedAt}
                  selectPort={selectHandler}
                />
              ))
            )}
          </div>
        </div>
      </Card>
      : null }
    </>
  );
};

export default PortList;
