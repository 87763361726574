import React, { Fragment, useEffect, useState} from 'react';

import fallbackImage from '../../awaiting-port.png';
import Button from '../../shared/components/UIElements/Buttons/Button';

import moment from 'moment';

import UpdatePortDesc from './UpdatePortDesc';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './PortDetail.css';
import UpdatePortLanguage from './UpdatePortLanguage';
import UpdatePortCurrency from './UpdatePortCurrency';

const PortDetail = ({ portId }) => {

   
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const [port, setPort] = useState();
    const [image, setImage] = useState('');

    useEffect(() => {
          const fetchPort = async () => {
            try {
              const responseData = await sendRequest(
                `https://api.docked.co/api/ports/${portId}`
                );
              setPort(responseData.port);
              console.log(responseData.port);
              setImage(`https://content.docked.co/img/ports/${responseData.port.portName.replace(" ", "-")}.jpg`)
              error && clearError();
            } catch (err) {
              console.log(err);
            }
          };
          fetchPort();
        
      }, [sendRequest, portId, ]);

      const imageFallBackHandler = () => {
        setImage(fallbackImage)
        
      }

return (
    <Fragment>
    
   
        <section id='port-detail'>
        {isLoading && <LoadingSpinner /> }
        {port ?
            <div className='Port-detail-info__wrapper'>
                <div className='port-detail-box port-image'>
                    <img src={image} onError={imageFallBackHandler} alt={port.portName}/>
                  </div>
                <div className='ship-detail-box ship-title'>
                    <h1>{port.portName}</h1>
                   
                    <h2>{port.country}</h2>
                    <img src={`https://docked.co/images/flags/${port.country}.png`} width='80px' alt={`${port.country} flag`} />
                </div>
                <div className='port-detail-box ship-info'>
                 <UpdatePortLanguage id={port.id} language={port.language} />
                 <UpdatePortCurrency id={port.id} currency={port.currency} />
                    
                    
                </div>
                <div className='port-detail-box port-today'>
                <h1>Ship in today</h1>
               
                
               

                </div>
            </div>
            
            : null }
            
            {port && 
            <div className='port-detail-itinerary__wrapper'>
               
              <UpdatePortDesc port={port} />
            </div>
            }


         {port && <p className='last-updated-port'>last updated: {moment(port.updatedAt).format('MMM Do YYYY')}</p>}
        </section>
        
    </Fragment>
);

}

export default PortDetail;