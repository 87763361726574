import React, { Fragment, useState, useEffect, useContext } from 'react';


import { convertToRaw, EditorState } from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './UpdatePortDesc.css';

const UpdatePortDesc = ({port, close}) => {


  const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest } = useHttpClient();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        
         if (port) {
           let contentState = stateFromHTML(port.description);
           setEditorState(EditorState.createWithContent(contentState));
         }
       }, [port, port.description]);

    const save = async () => {

        const desc = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        console.log(desc);
        try {
          await sendRequest(
            `https://api.docked.co/api/ports/update-port/description/${port.id}`,
            'PATCH',
            JSON.stringify({description: desc,}),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );

            close(desc);
    
        } catch (err) {
          console.log(err);
        }
      };

    const onEditorStateChange = state => {
        setEditorState(state);
      };

    return (
        <Fragment>
        {isLoading && <Spinner />}
        {error && <p className='course-form-editor-error'>{error}</p>}
       {port && <Editor
          wrapperClassName="course-form-editor-wrapper"
          editorClassName="editor"
          toolbarClassName="toolbar"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />}
        {!isLoading && <Button title='save' onClick={save}/>}
        </Fragment>

    );

}

export default UpdatePortDesc;